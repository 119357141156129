
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.aw-archive,
[class*='aw-archive--'] {
  > *:not(:first-child) {
    @include default-margin(0.6);
  }
}
