
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































































.aw-mood {
  @extend %border-radius-small;

  position: relative;
  overflow: hidden;
  transform: translate(0);
  background: $c-light-grey;
}

.aw-mood__content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  display: block;
  overflow: hidden;
  width: calc(100% - #{$spacing * 2});
  margin: $spacing;
  padding: $spacing;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($c-white, 80%);
    border-radius: 0.6rem;
    backdrop-filter: blur(1rem);
  }
}

.aw-mood__content__title {
  @extend %ff-alt;
  @extend %fw-bold;

  display: block;
  text-decoration: none;

  ::v-deep em {
    @extend %ff-default;
    @extend %fw-normal;
  }

  &::before {
    content: '';
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.aw-mood__content__text {
  min-height: 0;
  max-height: 0;
  opacity: 0;
  transition: 0.5s $ease-out-cubic;
  will-change: min-height, max-height, opacity;

  p {
    padding: $spacing * 0.5 0 $spacing * 2;
  }

  .aw-mood:hover &,
  .aw-mood:focus-within & {
    min-height: 3rem;
    max-height: 6em;
    opacity: 1;
  }
}

.aw-mood__picture {
  width: 100%;
  transition: transform 0.3s $ease-out-cubic;
  will-change: transform;

  img {
    width: 100%;
  }

  .aw-mood:hover &,
  .aw-mood:focus-within & {
    transform: scale(1.1);
  }
}

.aw-mood__content__icon {
  position: absolute;
  bottom: $spacing;
  left: $spacing;
  margin-top: $spacing * 0.75;
  color: $c-mustard;
  opacity: 0;
  transform: translateX(-2rem);
  transition: 0.1s $ease-out-cubic;
  will-change: transform, opacity;

  .aw-mood:hover &,
  .aw-mood:focus-within & {
    opacity: 1;
    transform: translateX(0);
    transition: 0.5s $ease-out-cubic;
  }
}
