
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.moods__item {
  width: 100%;
}
