
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































































$bp: 'l';

.aw-inspirations__heading {
  border-bottom: 0.1rem solid $c-lighter-grey;

  @include mq(m) {
    display: flex;
  }
}

.aw-inspirations__heading__title {
  margin-bottom: $spacing * 0.5;

  ::v-deep em {
    color: $c-dark-grey;
  }

  @include mq(m) {
    flex: 1;
    margin-bottom: 0;
    padding-right: $spacing * 2.5;
  }
}

.aw-inspirations__heading__text {
  @include mq(m) {
    width: col(6, 10);
  }
}

.aw-inspirations__item {
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 0.8rem;

  img {
    width: 100%;
  }
}

.aw-inspirations__button {
  &[class*='action--'] {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    transition: opacity 0.3s $ease-out-cubic;
  }

  &.is-hidden {
    opacity: 0;
  }
}

.card-inspirations-picture {
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  transform-style: preserve-3d;
}

.card-inspirations-button {
  z-index: 10;

  &::after {
    @include get-all-space;

    z-index: 20;
    display: block;
    content: '';
  }

  ::v-deep {
    .action__label {
      @include mq($bp) {
        overflow: hidden;
        width: 0;
        transition: all 0.2s ease-in-out;
      }
    }

    .action__icon-wrapper svg {
      margin-left: 0;
    }
  }

  @include mq($bp) {
    /* stylelint-disable-next-line declaration-no-important */
    width: 4.5rem !important;
    /* stylelint-disable-next-line declaration-no-important */
    height: 4.5rem !important;
    /* stylelint-disable-next-line declaration-no-important */
    padding: 1.5rem 1rem !important;
    /* stylelint-disable-next-line declaration-no-important */
    transition: all 0.3s ease-in-out !important;
  }
}

.card-inspirations {
  position: relative;
  aspect-ratio: 1;
  perspective: 100rem;

  a {
    z-index: 10;
  }

  img {
    object-fit: cover;
    object-position: center;
  }

  &:hover {
    .card-inspirations-picture {
      transform: rotateY(180deg);
    }

    .inspirations-picture-back {
      opacity: 1;
    }

    .card-inspirations-button {
      /* stylelint-disable-next-line declaration-no-important */
      width: max-content !important;
      /* stylelint-disable-next-line declaration-no-important */
      background-color: $c-white !important;
    }

    ::v-deep {
      .action__label {
        overflow: initial;
        width: auto;
      }
    }
  }
}

.card-inspiration-btn {
  @include get-all-space;

  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.5rem;
}

.card-inspirations-profile {
  position: absolute;
  z-index: 10;
  top: -3.75rem;
  left: 2rem;
  overflow: hidden;
  width: 7.5rem;
  aspect-ratio: 1;
  border: 1px solid $c-lighter-grey;
  border-radius: 100%;

  img {
    @include get-all-space;
  }
}

.inspirations-picture,
[class*='inspirations-picture-'] {
  @include get-all-space;

  backface-visibility: hidden;

  &[class*='-back'] {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    transform: rotateY(180deg);
  }

  img {
    height: 100%;
  }
}
